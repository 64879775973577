import PkElm from "../../../assets/Staking/PkElm.png";

export const DataStakeList = [
  {
    name: "Rewards availability",
    createTime: 1713838504,
    endTime: 1716430504,
    values: 20568.1986,
    id: 1,
    img: PkElm,
    isClaimed: false,
    TimeLine : 1,
  },
  {
    name: "Rewards availability",
    createTime: 1713838504,
    endTime: 18921700904,
    values: 50568.1986,
    id: 2,
    img: PkElm,
    isClaimed: false,
    TimeLine : 2,
  },
  {
    name: "Rewards availability",
    createTime: 1713838504,
    endTime: 1716430504,
    values: 10568.1986,
    id: 3,
    img: PkElm,
    isClaimed: false,
    TimeLine : 1,
  },
  {
    name: "Rewards availability",
    createTime: 1713838504,
    endTime: 1721700904,
    values: 210568.1986,
    id: 4,
    img: PkElm,
    isClaimed: false,
    TimeLine : 2,
  },
  {
    name: "Rewards availability",
    createTime: 1713838504,
    endTime: 1716430504,
    values: 5220568.1986,
    id: 5,
    img: PkElm,
    isClaimed: false,
    TimeLine : 1,
  },
  {
    name: "Rewards availability",
    createTime: 1713838504,
    endTime: 1721700904,
    values: 510568.1986,
    id: 6,
    img: PkElm,
    isClaimed: false,
    TimeLine : 2,
  },
  {
    name: "Rewards availability",
    createTime: 1713838504,
    endTime: 1716430504,
    values: 50568.1986,
    id: 7,
    img: PkElm,
    isClaimed: false,
    TimeLine : 1,
  },
  {
    name: "Rewards availability",
    createTime: 1713838504,
    endTime: 1721700904,
    values: 50568.1986,
    id: 8,
    img: PkElm,
    isClaimed: false,
    TimeLine : 2,
  },
  {
    name: "Rewards availability",
    createTime: 1713838504,
    endTime: 1716430504,
    values: 50568.1986,
    id: 9,
    img: PkElm,
    isClaimed: true,
    TimeLine : 1,
  },
];

export const DataStakeListFinished = [
  {
    name: "Rewards availability",
    createTime: 0,
    endTime: 0,
    values: 20568.1986,
    id: 1,
    img: PkElm,
    isClaimed: false,
    TimeLine : 1,
  },
  {
    name: "Rewards availability",
    createTime: 0,
    endTime: 0,
    values: 50568.1986,
    id: 2,
    img: PkElm,
    isClaimed: false,
    TimeLine : 2,
  },
];
