import Chart from "react-apexcharts";
import { ChartTimeTab, ChartWapper, TimBox } from "./styled";
import moment from "moment";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { ContextProviderWrapper } from "../../components/Context";
const ChartCommon = () => {
  const {theme} = useContext(ContextProviderWrapper)!
  const currentDate = new Date();
  const [timeTarget, setTimeTarget] = useState(0);
  const [dataRootTokent, setDataRootTokent] = useState([]);
  const [dataSwapTokent, setDataSwapTokent] = useState([]);
  const [dataChart, setDataChart] = useState<any>({ x: [], y: [] });
  const [dataChartTo, setDataChartTo] = useState<any>({ x: [], y: [] });
  const [fromTokent, setFromTokent] = useState(
    "EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c"
  );
  const [toTokent, setToTokent] = useState(
    "EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs"
  );
  const [formData, setFormData] = useState({
    token: fromTokent,
    currency: "usd",
    start_date: currentDate.getTime() - TimeLine[timeTarget].value,
    end_date: currentDate.getTime(),
  });
  useEffect(() => {
    if (formData.token && formData.end_date && formData.currency) {
      handleSubmit(fromTokent, 1);
      handleSubmit(toTokent, 2);
    }
  }, [formData, timeTarget]);
  const handleSubmit = (token: any, type: any) => {
    let a = Math.floor((formData.end_date - TimeLine[timeTarget].value) / 1000);

    axios
      .get(
        `https://tonapi.io/v2/rates/chart?token=${token}&currency=${
          formData.currency
        }&start_date=${a}&end_date=${Math.floor(
          formData.end_date / 1000
        )}&points_count=${TimeLine[timeTarget].poin}`
      )
      .then((response: any) => {
        type === 1
          ? setDataRootTokent(response?.data?.points?.reverse())
          : setDataSwapTokent(response?.data?.points?.reverse());
      })
      .catch((error) => {
        console.log("Error:", error);
      });

    axios
      .get(
        `https://ton.diamonds/api/v1/dex/udf/history?symbol=stonfi%3ATON%2FEQCdpz6QhJtDtm2s9-krV2ygl45Pwl-KJJCV1-XrP-Xuuxoq&resolution=60&from=1713323651&to=1713410051`
      )
      .then((response: any) => {
        console.log("responseDiamonds", response);
      })
      .catch((error) => {
        console.log("ErrorDiamonds:", error);
      });
  };
  useEffect(() => {
    dataRootTokent &&
      setDataChart({
        x: dataRootTokent?.map((item: any, index: number) => {
          const dateTime = moment.unix(item[0]);
          const day = dateTime.format("DD");
          const month = dateTime.format("MM");
          const year = dateTime.format("YYYY");
          const hour = dateTime.format("HH");
          const minute = dateTime.format("mm");
          return `${day}/${month}-${hour}:${minute}`;
        }),
        y: dataRootTokent.map((num: any) => num[1]?.toFixed(3)),
      });
  }, [dataRootTokent]);
  useEffect(() => {
    dataSwapTokent &&
      setDataChartTo({
        x: dataSwapTokent?.map((item: any, index: number) => {
          const dateTime = moment.unix(item[0]);
          const day = dateTime.format("DD");
          const month = dateTime.format("MM");
          const year = dateTime.format("YYYY");
          const hour = dateTime.format("HH");
          const minute = dateTime.format("mm");
          return `${day}/${month}-${hour}:${minute}`;
        }),
        y: dataSwapTokent.map((num: any) => num[1]?.toFixed(3)),
      });
  }, [dataSwapTokent]);

  const [typeShow, setTypeShow] = useState(1);

  const options: any = {
    options: {
      chart: {
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              //   dateFormatter(timestamp) {
              //     return new Date(timestamp).toDateString();
              //   },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: "zoom",
        },
      },
      colors: ["#0FF586", "#fff"],
      fill: {
        type: "gradient",
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 5,
        hover: {
          size: 9,
        },
      },
      stroke: {
        curve: "smooth",
      },
      labels: typeShow === 1 ? dataChart.x : dataChartTo.x,
      xaxis: {
        labels: {
          style: {
            colors: "#fff",
          },
        },
      },
      yaxis: {
        decimalsInFloat: 1,
        opposite: true,
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            yaxis: {
              show: false,
            },
          },
        },
      ],
    },
    series: [
      {
        name: "Ton",
        data: typeShow === 1 ? dataChart.y : dataChartTo.y,
      },
    ],
    // yaxis: {
    //   opposite: true,
    //   labels: {
    //     style: {
    //       colors: "#fff",
    //     },
    //   }
    // },
  };
  return (
    <ChartWapper>
      <ChartTimeTab>
        {TimeLine.map((item: any, index: number) => (
          <TimBox
            key={item.value}
            onClick={() => {
              setTimeTarget(index);
            }}
          >
            {item.label}
          </TimBox>
        ))}
      </ChartTimeTab>
      <Chart
        options={options.options}
        series={options.series}
        type="area"
        height={350}
      />
      <ChartTimeTab>
        <TimBox
          onClick={() => {
            setTypeShow(1);
          }}
        >
          Ton
        </TimBox>
        <TimBox
          onClick={() => {
            setTypeShow(2);
          }}
        >
          jUSDT
        </TimBox>
      </ChartTimeTab>
    </ChartWapper>
  );
};
export default ChartCommon;

const TimeLine = [
  {
    label: "1 Day",
    value: 86400000,
    poin: 24,
  },
  {
    label: "1 Week",
    value: 604800000,
    poin: 7,
  },
  {
    label: "1 Month",
    value: 2629743000,
    poin: 30,
  },
];
