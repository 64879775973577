import styled from "styled-components";

export const ChartWapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  .apexcharts-gridline {
    display: none !important;
  }
  .apexcharts-area {
    stroke-width: 1.5px;
  }
`;
export const ChartTimeTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
export const TimBox = styled.div`
  color: #fff;
  padding: 10px;
`;
