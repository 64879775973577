import styled from "styled-components";
import close from "../../../assets/Dashboard/Common/close.svg";

export const ModalOverlayContainer = styled.div<{ open: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 0 15px;
  opacity: ${({ open }) => (open ? "1" : "0")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  transition: all 0.15s ease-in-out;
  z-index: 500;
`;
export const ModalOverlayWrapper = styled.div<{ width: any }>`
  position: relative;
  padding: 0 15px;
  margin: 0 auto;
  max-width: ${({ width }) => width};
  width: 100%;
  @media screen and (max-width: 767px) {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    padding: 0 10px;
    width: calc(100% - 50px);
    &.active {
      transform: translateY(-13%);
    }
    transition: all 0.3s linear;
  }
`;
export const ModalOverlayInner = styled.div`
  border-radius: 16px;
  border: 2px solid #4d4d57;
  background: #1c1c1e;
  box-shadow: 0px 0px 114.031px 0px rgba(106, 126, 228, 0.15);
  padding: 20px;
  &.light {
    border-color: #92929e;
    background: #fff;
  }
  @media screen and (max-width: 767px) {
    padding: 25px 16px;
  }
`;
export const ModalClose = styled.div`
  position: relative;
  top: -3px;
  width: 31px;
  height: 31px;
  background: url(${close}) no-repeat center / 100% auto;
  cursor: pointer;
  transition: all 0.15s linear;
  &:hover {
    opacity: 0.7;
  }
`;
export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
`;
export const ModalTitle = styled.p`
  color: #fff;
  font-size: 24px;
  line-height: 32px;
  font-family: DirtyLine;
  text-transform: lowercase;
  &.light {
    color: #43424a;
  }
  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
`;
