import styled from "styled-components";

export const BackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  margin-bottom: 20px;
  gap: 10px;
  cursor: pointer;
  transition: all .15s linear;
  &:hover {
    opacity: 0.7;
  }
  p {
    color: #828282;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.28px;
  }
`;
